@import url('https://fonts.googleapis.com/css?family=Great+Vibes|Muli:300,400,500,600,700&display=swap');
:root {
  --primaryColor: #910322;
  --secondaryColor: #064d5e;
  --thirdColor: #d26d00;
  --mainWhite: #ffffff;
  --mainBlack: #000000;
}

h1,
h2,
h3 {
  font-family: 'Great Vibes', cursive;
}
body {
  font-family: 'Muli', sans-serif;
}
a {
  text-decoration: none;
  transition: all 0.3s;
}
a:hover {
  text-decoration: none !important;
}
img {
  width: 100%;
}
.section-padding {
  padding: 140px 0 110px;
}

.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .section-padding {
    padding: 130px 0 100px;
  }
}
