:root {
  --primaryColor: #daa520;
  --secondaryColor: #ab008e;
  --thirdColor: #ffc700;
  --mainWhite: #ffffff;
  --mainBlack: #000000;
}

ul.smothscroll {
  position: fixed;
  bottom: 0px;
  right: 30px;
  list-style: none;
}
ul.smothscroll a {
  width: 50px;
  height: 50px;
  background: var(--primaryColor);
  color: #fff;
  display: block;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
}
